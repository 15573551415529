// @ts-nocheck
import React, { Component, Fragment } from "react";
import classnames from 'classnames';
import Icon from '@components/Icon';

import styles from './style.module.scss';
interface CheckBoxProps {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  children?: React.ReactNode;
  value: any
  classStyle?: string
  depositChecked?: any 
}

class CheckBox extends Component<CheckBoxProps> {
  constructor(props) {
    super(props)
  }

  handleOnchange = (e) => {
    const {
      onChange,
      value
    } = this.props;
    if (onChange) {
      onChange(value);
    }
  }

  render() {
    const { checked, value, classStyle, depositChecked } = this.props as CheckBoxProps;
    const btnClass = classnames({
      'checked': checked,
      'deposit-checked': depositChecked
    });
    return (
      <Fragment>
        <div className={styles['checkbox-container']} onClick={this.handleOnchange}>
          <div className={`${styles['checkbox-place']} ${styles[`${btnClass}`]} ${styles[classStyle]}`}>
            <div className={styles['check-icon']}>
              <Icon name='Tick' />
            </div>
          </div>
          <div className={styles['checkbox-txt']}>{value}</div>
        </div>
      </Fragment>

    );
  }
}

export default CheckBox;