// @ts-nocheck
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';
import Search from './Search';
import SearchTabs from './SearchTabs';

export default class NormalSearch extends Component {
    render() {
        const {
            pickup,
            dropoff,
            onCity,
            onLandmark,
            onPick,
            onSearch,
            oPlace,
            onSwitch,
            dataTj,
            btnText = '搜索',
            centerTip,
            isShowEasyRentTip = true,
            tabs,
            selectZhima,
            onChange,
            hasLogin,
            zhimaAuth,
            zhimaAuthCode,
            zmChecked,
            resetAuthZhima,
            onAccount,
            checkbox
        } = this.props;
        return (
            <Fragment>
                <div className={styles['search-filter-container']}>
                    <div className={styles['search-filter-condition']}>
                        {/* <SearchTabs /> */}
                        {tabs}
                        <Search
                            isShowEasyRentTip={isShowEasyRentTip}
                            cls={tabs ? "pop-normal" : "no-tab"}
                            open={oPlace}
                            pickup={pickup}
                            dropoff={dropoff}
                            onCity={onCity}
                            onLandmark={onLandmark}
                            onPick={onPick}
                            onSearch={onSearch}
                            onSwitch={onSwitch}
                            btnText={btnText}
                            btnClass="pick-up-search-btn-normal"
                            centerTip={centerTip}
                            tj={dataTj || '首页_点击_搜索按钮'}
                            onAuthChange={onChange}
                            selectZhima={selectZhima}
                            hasLogin={hasLogin}
                            zhimaAuthCode={zhimaAuthCode}
                            zmChecked={zmChecked}
                            zhimaAuth={zhimaAuth}
                            resetAuthZhima={resetAuthZhima}
                            onAccount={onAccount}
                            checkbox={checkbox}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

NormalSearch.propTypes = {
    // 取车城市
    onCity: PropTypes.func,
    // 取车地标
    onLandmark: PropTypes.func,
    // 取车日期
    onPick: PropTypes.func,
    // 城市信息
    pickup: PropTypes.shape({
        cityName: PropTypes.string,
        cityCode: PropTypes.string,
        address: PropTypes.string,
        landmarkName: PropTypes.string,
    }),
    btnText: PropTypes.string,
};

NormalSearch.defaultProps = {
    onCity: () => {},
    onLandmark: () => {},
    onPick: () => {},
    btnText: '搜索',
    pickup: {
        cityName: '广州',
        cityCode: '111',
        address: '新白云机场',
        landmarkName: '新白云机场',
    },
};
