import React, { Component, Fragment } from 'react';

import styles from './style.module.scss';
import { jumpLink, getTopDomain } from '@/utils/url';
import {
    getAppVersionByUa,
    isAndroid,
    isIos,
    isCrcApp
} from '@/utils/native';
import { versionCompare } from '@/utils/tools';


const topDomain = getTopDomain();
// const zzcHost = (topDomain && topDomain === 'com') ? `https://m.zuzuche.${topDomain}/?hidetabs=1&ref=crc` : 'https://m_cn_rental.zuzuche.net/?hidetabs=1&ref=crc';
const zzcHost = `https://m.zuzuche.${topDomain}/?hidetabs=1&ref=crc`;
const { showZZCTab } = window.__initData;

class SearchTabs extends Component {
    appShouZZCTab: boolean;
    constructor(props) {
        super(props);
        this.appShouZZCTab = false;
        if (isCrcApp()) {
            const version = isCrcApp() && (getAppVersionByUa());
            const appShouZZCTab = !!((
                (isIos() && versionCompare(version, '1.1.4', 3) !== -1)
            || (isAndroid() && versionCompare(version, '1.0.3', 3) !== -1)
            ));
            this.appShouZZCTab = appShouZZCTab;
        }
    }

    handleSwitchZZC = () => {
        jumpLink({
            url: zzcHost
        });
    }

    render() {
        // if (isCrcApp) {
        //     return null;
        // }
        return (showZZCTab && (isCrcApp() && this.appShouZZCTab)) ? (
            <Fragment>
                <div className={styles['tabs-container']}>
                    <div
                        className={`${styles['tabs-item']} ${styles['left-item']} ${
                            styles.curr
                        }`}
                    >
                        境内租车
                    </div>
                    <div
                        className={`${styles['tabs-item']} ${styles['right-item']}`}
                        onClick={this.handleSwitchZZC}
                    >
                        境外租车
                    </div>
                </div>
            </Fragment>
        ) : null;
    }
}

export default SearchTabs;
