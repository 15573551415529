// @ts-nocheck
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'zzc-design-mobile';
import Search from './Search';

export default class PopupSearch extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            isShowSearch,
            pickup,
            dropoff,
            onCity,
            onLandmark,
            onPick,
            onSearch,
            oPlace,
            onSwitch,
            type = 'white'
        } = this.props;
        return (
            <Fragment>
                <Popup
                    maskClose={true}
                    visible={isShowSearch}
                    direction="top"
                    onClose={() => {
                        this.props.onClose();
                    }}
                >
                    <Search
                        type={type}
                        cls="pop-top"
                        open={oPlace}
                        pickup={pickup}
                        dropoff={dropoff}
                        onCity={onCity}
                        onLandmark={onLandmark}
                        onPick={onPick}
                        onSearch={onSearch}
                        onSwitch={onSwitch}
                        btnClass="pick-up-search-btn-shadow"
                        btnText="重新搜索"
                        tj={'search_click_reSearch'}
                    />
                </Popup>
            </Fragment>
        );
    }
}

PopupSearch.propTypes = {
    // 取车城市
    onCity: PropTypes.func,
    // 取车地标
    onLandmark: PropTypes.func,
    // 取车日期
    onPick: PropTypes.func,
    // 城市信息
    pickup: PropTypes.shape({
        cityName: PropTypes.string,
        cityCode: PropTypes.string,
        address: PropTypes.string,
        landmarkName: PropTypes.string,
    }),
    btnText: PropTypes.string,
};

PopupSearch.defaultProps = {
    onCity: () => {},
    onLandmark: () => {},
    onPick: () => {},
    btnText: '搜索',
    pickup: {
        cityName: '广州',
        cityCode: '111',
        address: '新白云机场',
        landmarkName: '新白云机场',
    },
};
