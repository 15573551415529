import React, { Fragment, LegacyRef } from 'react';
import BottomArrow from '@components/BottomArrow';
import SwitchButton from '@components/SwitchButton';
import { Tag } from '@common/Widget';
import Toast from 'zzc-design-mobile/lib/Toast';
import { PRIVILEGE_LISTS } from '@/utils/const';
import { isObject, isString } from '@utils/type';
import Icon from '@components/Icon';
import DateUitl from '@utils/date';
import { isBetweenNight } from '@utils/format-search-date';
import { isEmptyObject } from '@utils/type';
import {
    isZZCApp,
    isCrcApp,
    isOneOfMiniprograms,
    isApp,
    isCgbApp,
    isAliPayMiniprogram,
    isRewardsApp,
    isCarRentalApp,
    isOneOfQuickApp,
} from '@utils/native';
import CheckBox from './CheckBox';
import SearchBtnRef from './SearchBtnRef';
import styles from './style.module.scss';
import { pushEvent } from '@utils/za';

const InvalidDate = 'Invalid Date';
const SEARCH_CALENDAR_CONFIG = window.__initData.searchCalendarConfig || {};
function PickTextBtn({ text }) {
    return <div className={styles['pick-txt-tip']}>{text}</div>;
}

function Tips({ text }) {
    return (
        <div className={styles['tips-box']}>
            <Icon name="Sigh" />
            <div className={styles['my-text']}>
                <span className={styles['tips-text']}>{text}</span>
            </div>
        </div>
    );
}

function DateBox({ time }) {
    return (
        <div className={styles['date-box']}>
            <div className={styles['date-time']}>{DateUitl.format(time, 'MM月DD日')}</div>
            <div className={styles['time-box']}>
                <div className={styles['time-week']}>{DateUitl.week(time)}</div>
                <div className={styles['hour']}>{DateUitl.format(time, 'HH:mm')}</div>
            </div>
        </div>
    );
}

function SearchBtn({ btnClass, btnText = '搜索', onSearch, tj }) {
    return (
        <div
            ref={SearchBtnRef as LegacyRef<HTMLDivElement>}
            className={`${styles['pick-up-search-btn']} ${styles[btnClass]}`}
            data-tj={tj}
            onClick={() => onSearch()}
        >
            {btnText}
        </div>
    );
}

const { pickUpTimeAlert, timeAlert, holidayAlerts } = SEARCH_CALENDAR_CONFIG;
function HolidayAlert(props) {
    const { pickTime, dropTime, days } = props;
    const show = days < 5 ? true : false;
    let isShow = false,
        content = '国庆为租车高峰期，部分车行需要至少5天起租喔';
    if (holidayAlerts) {
        const holiday =
            DateUitl.isBetweenHoliday(pickTime, holidayAlerts) ||
            DateUitl.isBetweenHoliday(dropTime, holidayAlerts);
        if (holiday) {
            isShow = !!holiday && show;
            content = holiday.content;
        }
    } else {
        // 没有配置使用旧版
        const isBetweenNational =
            DateUitl.isBetweenNational(pickTime) || DateUitl.isBetweenNational(dropTime);
        isShow = isBetweenNational && show;
    }
    return isShow && <Tips text={content} />;
}

function NightAlert(props) {
    const { pickup, dropoff } = props;
    const isNight = isBetweenNight(pickup.pickTime) || isBetweenNight(dropoff.dropTime);
    if (isNight) {
        return (
            <Tips
                text={
                    timeAlert
                        ? timeAlert.content
                        : '21:00-08:00取还车可能会被收取￥20-100/次夜间服务费'
                }
            />
        );
    }
    return null;
}

function LessFourHours(props) {
    const diffPickNow = props.diffPickNow;
    if (pickUpTimeAlert) {
        return (
            diffPickNow < pickUpTimeAlert.limitHours && (
                <Tips text={pickUpTimeAlert.content} />
            )
        );
    }
    let minHour = 4,
        tips = '取车时间距离当前时间较近，车辆库存紧张，建议您现在就搜索下单';
    if (!isEmptyObject(SEARCH_CALENDAR_CONFIG.pickUpTimeAlert)) {
        minHour = parseInt(SEARCH_CALENDAR_CONFIG.pickUpTimeAlert.limitHours);
        tips = SEARCH_CALENDAR_CONFIG.pickUpTimeAlert.content;
    }
    return diffPickNow < minHour && <Tips text={tips} />;
}

export default function Search(props) {
    const {
        pickup,
        dropoff,
        checkList,
        onChange,
        onSwitch,
        onPick,
        onLandmark,
        onCity,
        onSearch,
        open,
        btnClass,
        btnText,
        cls,
        tj,
        isShowEasyRentTip = false,
        type,
        centerTip,
        selectZhima,
        onAuthChange,
        hasLogin,
        zhimaAuth,
        zhimaAuthCode,
        zmChecked,
        resetAuthZhima,
        onAccount,
        checkbox: { checkbox = false, name = '', linkText = '' } = {},
    } = props;
    const app =
        isCarRentalApp() || isOneOfMiniprograms() || isOneOfQuickApp()
            ? 'zzc-app'
            : 'zzc-h5';
    const { times, days, hours } = DateUitl.diff({
        start: pickup.pickTime,
        end: dropoff.dropTime,
    });
    // 比对取车时间和当前时间
    const diffPickNow = Math.abs(
        DateUitl.parseDiff({
            start: pickup.pickTime,
            end: Date.now(),
        })
    );

    const handlePickSwitch = () => {
        pushEvent(`搜索框组件_${(open ? '关闭' : '打开')}_异地取换switch`);
        onSwitch();
    };

    // 库存量预警
    const stocktips = DateUitl.stockGrowth(pickup.pickTime, dropoff.dropTime);
    return (
        <div
            className={`${styles['pick-up-container']} ${styles[cls]} ${styles[app]} ${
                styles[type]
            }`}
        >
            <div className={styles['pick-up-wrapper']}>
                <div className={styles['pick-up-content']}>
                    <div className={styles['pick-up-city']}>
                        <div
                            className={`${styles['pick-up-city-item']} ${
                                styles['pick-city']
                            }`}
                        >
                            <PickTextBtn text="取车城市" />
                            <div
                                className={`${styles['txt-strong']} ${
                                    styles['pick-flex']
                                } ${styles['city-box']}`}
                                onClick={e => onCity('pick', e)}
                            >
                                {
                                    <Fragment>
                                        <div className={styles['city-name']}>
                                            {pickup.cityName ? pickup.cityName : '北京'}
                                        </div>
                                        <div className={styles['area-icon']}>
                                            <Icon name="Ic_driver_into2" />
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        </div>
                        <div
                            className={`${styles['pick-up-city-item']} ${
                                styles['pick-area']
                            }`}
                        >
                            <PickTextBtn text="取车地点" />
                            <div
                                className={`${styles['pick-txt-main']} ${
                                    styles['txt-strong']
                                }`}
                                onClick={e => onLandmark('pick', e)}
                            >
                                {pickup.landmarkName
                                    ? pickup.landmarkName
                                    : '首都机场T2航站楼'}
                            </div>
                        </div>
                        <div
                            className={`${styles['pick-up-city-item']} ${
                                styles['pick-other']
                            }`}
                        >
                            <PickTextBtn text="异地还车" />
                            <div className={styles['pick-switch']}>
                                <SwitchButton
                                    open={open}
                                    onSwitch={handlePickSwitch}
                                />
                            </div>
                        </div>
                    </div>
                    {open && (
                        <Fragment>
                            <div
                                className={`${styles['pick-up-city']} ${
                                    styles['pick-up-other-city']
                                }`}
                            >
                                <div
                                    className={`${styles['pick-up-city-item']} ${
                                        styles['pick-city']
                                    }`}
                                >
                                    <PickTextBtn text="还车城市" />
                                    <div
                                        className={`${styles['txt-strong']} ${
                                            styles['pick-flex']
                                        } ${styles['city-box']}`}
                                        onClick={e => {
                                            type === 'black'
                                                ? Toast.info('仅支持同城取还车')
                                                : onCity('drop', e);
                                        }}
                                    >
                                        {dropoff.cityName ? (
                                            <Fragment>
                                                <div className={styles['city-name']}>
                                                    {dropoff.cityName}
                                                </div>
                                                <div className={styles['area-icon']}>
                                                    <Icon name="Ic_driver_into2" />
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={`${styles['pick-up-city-item']} ${
                                        styles['pick-area']
                                    }`}
                                >
                                    <PickTextBtn text="还车地点" />
                                    <div
                                        className={`${styles['pick-txt-main']} ${
                                            styles['txt-strong']
                                        }`}
                                        onClick={e => onLandmark('drop', e)}
                                    >
                                        {dropoff.landmarkName}
                                    </div>
                                </div>
                            </div>
                            {pickup.landmarkName !== dropoff.landmarkName && (
                                <Tips text="可能产生异地还车费" />
                            )}
                        </Fragment>
                    )}

                    <div className={styles['pick-up-date']}>
                        <div
                            className={styles['pick-up-date-item']}
                            data-tj="首页_打开_取还车时间选择器_1"
                            onClick={e => onPick('pickTime', e)}
                        >
                            {DateUitl.format(pickup.pickTime, 'MM月DD日') ===
                                InvalidDate ||
                            DateUitl.format(dropoff.dropTime, 'MM月DD日') ===
                                InvalidDate ? (
                                <div className="">请选择取车时间</div>
                            ) : (
                                <DateBox time={pickup.pickTime} />
                            )}
                        </div>
                        <div className={styles['pick-up-date-day']}>
                            {days > 0 || hours > 0 ? (
                                <div className={styles['duration']}>{times}</div>
                            ) : (
                                <div className={styles['duration-placeholer']}>
                                    请选择时间
                                </div>
                            )}
                            <BottomArrow />
                        </div>
                        <div
                            className={`${styles['pick-up-date-item']} ${
                                styles['txt-right']
                            }`}
                            data-tj="首页_打开_取还车时间选择器_2"
                            onClick={e => onPick('dropTime', e)}
                        >
                            {DateUitl.format(pickup.pickTime, 'MM月DD日') ===
                                InvalidDate ||
                            DateUitl.format(dropoff.dropTime, 'MM月DD日') ===
                                InvalidDate ? (
                                <div className="">请选择还车时间</div>
                            ) : (
                                <DateBox time={dropoff.dropTime} />
                            )}
                        </div>
                    </div>
                    {/* 夜间取车 */}
                    <NightAlert pickup={pickup} dropoff={dropoff} />
                    {stocktips && <Tips text={stocktips} />}
                    {(isString(centerTip) ? <Tips text={centerTip} /> : centerTip) ??
                        null}
                    <HolidayAlert
                        pickTime={pickup.pickTime}
                        dropTime={dropoff.dropTime}
                        days={days}
                    />

                    {/* 取车时间是否与当前时间间隔是否小于4小时 */}
                    <LessFourHours diffPickNow={diffPickNow} />

                    {checkList && (
                        <div className={styles['check-box-container']}>
                            {checkList.map(item => (
                                <CheckBox
                                    key={item.value}
                                    value={item.value}
                                    checked={item.checked}
                                    onChange={onChange}
                                />
                            ))}
                        </div>
                    )}
                    {((isApp() && !isCgbApp()) || isAliPayMiniprogram()) && name && checkbox && (
                        <div className={styles['deposit-zhima']}>
                            <div className={styles['deposit-left']}>
                                {checkbox && (
                                    <CheckBox
                                        classStyle="deposit-btn"
                                        value={selectZhima}
                                        depositChecked={selectZhima}
                                        onChange={onAuthChange}
                                    />
                                )}
                                <Icon
                                    name="Sesame"
                                    style={{ color: '#00A0E9', fontSize: '.31rem' }}
                                />
                                <span
                                    className={styles['deposit-text']}
                                    dangerouslySetInnerHTML={{ __html: name }}
                                ></span>
                            </div>
                            {!!linkText && (
                                <div
                                    className={styles['deposit-auth']}
                                    onClick={event => {
                                        if (zmChecked) {
                                            resetAuthZhima();
                                        } else if (hasLogin) {
                                            zhimaAuth('zhima');
                                        } else {
                                            onAccount(event);
                                        }
                                    }}
                                >
                                    {linkText}
                                    <Icon
                                        name="Ic_arrow_right_zzc"
                                        style={{ fontSize: '.26rem' }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <SearchBtn
                        btnText={btnText}
                        onSearch={onSearch}
                        tj={tj}
                        btnClass={btnClass}
                    />
                    {/* {isShowEasyRentTip ? (
                        <div
                            className={styles['easy-rent-tip']}
                            onClick={() =>
                                window.location.assign(
                                    'https://sbt.zuzuche.com/sbt/zijia_easy'
                                )
                            }
                        >
                            <Tag style="easyRent" isEasyRentAll />
                            <p className={styles['m-text']}>
                                <span className={styles['u-text']}>
                                    新车·押金双免·送车上门
                                </span>
                                <span className={styles['u-link']}>
                                    享9大特权
                                    <i className={styles['u-icon']}>
                                        <Icon name="Home_arrow_right" />
                                    </i>
                                </span>
                            </p>
                        </div>
                    ) : null} */}
                </div>
            </div>
        </div>
    );
}
